import './Acasa.css';
import helmetImg from '../../assets/halmet.png';
import service01 from '../../assets/service-01.png';
import service02 from '../../assets/service-02.png';
import service03 from '../../assets/service-03.png';
import { Slide } from "react-awesome-reveal";
import img1 from '../../assets/portofoliu/img1.jpg';
import img2 from '../../assets/portofoliu/img2.jpg';
import img3 from '../../assets/portofoliu/img3.jpg';
import img4 from '../../assets/portofoliu/img4.jpg';
import img5 from '../../assets/portofoliu/img5.jpg';
import img6 from '../../assets/portofoliu/img6.jpg';
import img7 from '../../assets/portofoliu/img7.jpg';
import img8 from '../../assets/portofoliu/img8.jpg';
import img9 from '../../assets/portofoliu/img9.jpg';
import img10 from '../../assets/portofoliu/img10.jpg';
import img11 from '../../assets/portofoliu/img11.jpg';
import img12 from '../../assets/portofoliu/img12.jpg';

import { useTranslation } from 'react-i18next';

function Acasa(props) {
  const { t } = useTranslation();

  return (
    <div className='acasa-div'>
      <div className='overlay'>
        <div className='overlay-filter'>
          <Slide direction='up' triggerOnce='true' className='padding'>
            <div className='overlay-content'>
              <h1 className='overlay-title'>{t('company_name')}</h1>
              <p className='overlay-subtitle'>{t('overlay_subtitle')}</p>
              <p className='overlay-services'>Servicii construcții, Arhitectură, Rezistență, Instalații</p>
              <p className='overlay-descriere'>{t('overlay_description')}</p>
              <button className='secondary-btn' onClick={()=>{props.footerChangePage('despre')}}>{t('learn_more')}</button>
            </div>
          </Slide>
        </div>
      </div>

      <div className='acasa-servicii'>
        <p>{t('responsibility_efficiency')}</p>
        <h2>{t('our_services')}</h2>
        <div className='lista-servicii padding'>
          <div className='card-servicii'>
            <img src={service01} alt="" className='blue-filter' />
            <h2>{t('architecture')}</h2>
            <p>{t('architecture_description')}</p>
          </div>
          <div className='card-servicii'>
            <img src={service02} alt="" className='blue-filter'/>
            <h2>{t('resistance')}</h2>
            <p>{t('resistance_description')}</p>
          </div>
          <div className='card-servicii'>
            <img src={service03} alt="" className='blue-filter'/>
            <h2>{t('installations')}</h2>
            <p>{t('installations_description')}</p>
          </div>
          <div className='card-servicii'>
            <img src={helmetImg} alt="" className='blue-filter'/>
            <h2>{t('specialized_studies')}</h2>
            <p>{t('specialized_studies_description')}</p>
          </div>
        </div>
        <button className='secondary-btn' onClick={()=>{props.footerChangePage('despre')}}>{t('learn_more_button')}</button>
      </div>

      <div className='portofoliu-div padding'>
        <p>{t('works')}</p>
        <h2>{t('completed_works')}</h2>
        <div className='portofoliu-poze'>
          <img src={img1} alt="construction-image" className='img' />
          <img src={img2} alt="construction-image" className='img' />
          <img src={img3} alt="construction-image" className='img' />
          <img src={img4} alt="construction-image" className='img' />
          <img src={img5} alt="construction-image" className='img' />
          <img src={img6} alt="construction-image" className='img' />
          <img src={img7} alt="construction-image" className='img' />
          <img src={img8} alt="construction-image" className='img' />
          <img src={img9} alt="construction-image" className='img' />
          <img src={img10} alt="construction-image" className='img' />
          <img src={img11} alt="construction-image" className='img' />
          <img src={img12} alt="construction-image" className='img' />
        </div>
      </div>
      <div className='padding bg'>
        <p>{t('why_us')}</p>
        <h2>{t('our_team_quality_integrity')}</h2>
        <p>{t('have_project')}</p>
        <button className='secondary-btn' onClick={() => { props.footerChangePage('contact') }}>{t('contact_us')}</button>
      </div>
    </div>
  );
}

export default Acasa;
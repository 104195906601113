import './Footer.css';
import { FaRegClock } from "react-icons/fa6";
import { PiPhoneDuotone } from "react-icons/pi";
import { GoDotFill } from "react-icons/go";
import { useTranslation } from 'react-i18next';
import { FaFacebook } from "react-icons/fa";


function Footer(props) {
  const { t } = useTranslation();
  return (
    <div className='padding footer-div'>
      <div className='footer-content'>
        <div className='legal'>
          <h3>{t('legal.company_name')}</h3>
          <p>{t('legal.description')}</p>
          <a href={t('legal.anpc_link')} className='anpc' target='_blank' rel='noopener noreferrer'>a</a>
          <a href={t('legal.litigii_link')} className='litigii' target='_blank' rel='noopener noreferrer'>a</a>
        </div>
        <ul className='linkuri-footer'>
          <p>{t('useful_links.title')}</p>
          <li onClick={()=>{props.footerChangePage('acasa')}}><GoDotFill className='orange-dot'/>{t('useful_links.home')}</li>
          <li onClick={()=>{props.footerChangePage('despre')}}><GoDotFill className='orange-dot'/>{t('useful_links.about_us')}</li>
          <li onClick={()=>{props.footerChangePage('servicii')}}><GoDotFill className='orange-dot'/>{t('useful_links.services')}</li>
          <li onClick={()=>{props.footerChangePage('contact')}}><GoDotFill className='orange-dot'/>{t('useful_links.contact')}</li>
        </ul>
        <ul className='servicii-footer'>
          <p>{t('services.title')}</p>
          <li onClick={()=>{props.footerChangePage('servicii')}}><GoDotFill className='orange-dot'/>{t('services.architecture')}</li>
          <li onClick={()=>{props.footerChangePage('servicii')}}><GoDotFill className='orange-dot'/>{t('services.structural_engineering')}</li>
          <li onClick={()=>{props.footerChangePage('servicii')}}><GoDotFill className='orange-dot'/>{t('services.installations')}</li>
          <li onClick={()=>{props.footerChangePage('servicii')}}><GoDotFill className='orange-dot'/>{t('services.specialized_studies')}</li>
        </ul>
        <div className='contact-footer'>
          <p>{t('contact-footer.title')}</p>
          <p style={{display:'flex', columnGap:'8px'}}><FaRegClock className='orange-dot scale2'/>{t('contact-footer.working_hours')}</p>
          <p>{t('contact-footer.weekend_closed')}</p>
          <div style={{display:'flex', alignItems:'center'}}><PiPhoneDuotone className='orange-dot scale'/><a href={`tel:${t('contact-footer.phone')}`} className='tel'>{t('contact-footer.phone')}</a></div>
          <a href="https://www.facebook.com/profile.php?id=61555676706565" target='_blank' rel='noopener noreferrer'><FaFacebook className='facebook'/></a>
        </div>
      </div>
    </div>
  );
}

export default Footer;
import './Servicii.css';
import { FaCheckSquare } from "react-icons/fa";
import { Slide } from "react-awesome-reveal";
import { useTranslation } from 'react-i18next';

function Servicii(props) {
  const { t } = useTranslation();

  return (
    <div className='servicii-div'>
      <div className='servicii-top'>
        <h2><Slide direction='up' triggerOnce='true'>{t('serviciile_noastre')}</Slide></h2>
      </div>

      <div className='servicii-list-div padding'>
        <div className='servicii-card'>
          <h2 className='arhitectura'>{t('arhitectura')}</h2>
          <ul className='servicii-list'>
            {t('arhitectura_services', { returnObjects: true }).map((service, index) => (
              <li key={index}><FaCheckSquare className='orange' /> {service}</li>
            ))}
          </ul>
        </div>
        <div className='servicii-card'>
          <h2 className='rezistenta'>{t('rezistenta')}</h2>
          <ul className='servicii-list'>
            {t('rezistenta_services', { returnObjects: true }).map((service, index) => (
              <li key={index}><FaCheckSquare className='orange' /> {service}</li>
            ))}
          </ul>
        </div>
        <div className='servicii-card'>
          <h2 className='instalatii'>{t('instalatii')}</h2>
          <ul className='servicii-list'>
            {t('instalatii_services', { returnObjects: true }).map((service, index) => (
              <li key={index}><FaCheckSquare className='orange' /> {service}</li>
            ))}
          </ul>
        </div>
        <div className='servicii-card'>
          <h2 className='studii'>{t('studii_de_specialitate')}</h2>
          <ul className='servicii-list'>
            {t('studii_de_specialitate_services', { returnObjects: true }).map((service, index) => (
              <li key={index}><FaCheckSquare className='orange' /> {service}</li>
            ))}
          </ul>
        </div>
      </div>
      <button className='secondary-btn' onClick={() => { props.footerChangePage('contact') }}>{t('contacteaza_ne')}</button>
    </div>
  );
}

export default Servicii;
import { useState } from 'react';
import './Contact.css';
import { IoLocationSharp } from "react-icons/io5";
import { PiPhoneDuotone } from "react-icons/pi";
import emailjs from '@emailjs/browser';
import { FaCheck } from "react-icons/fa";
import { Fade } from 'react-awesome-reveal';
import { useTranslation } from 'react-i18next';
import { FaFacebook } from "react-icons/fa";

function Contact() {
    const { t } = useTranslation();

    const [nume, setNume] = useState('');
    const [prenume, setPrenume] = useState('');
    const [email, setEmail] = useState('');
    const [subiect, setSubiect] = useState('');
    const [mesaj, setMesaj] = useState('');
    const [acord, setAcord] = useState(false);
    const [eroare, setEroare] = useState(false);
    const [success, setSuccess] = useState(false);

    const trimiteMesaj = (e) => {
        e.preventDefault();
        if (nume && prenume && email && subiect && mesaj && acord) {
            emailjs.send("service_j0agb4o", "template_29bxjkp", {
                from_nume: nume,
                from_prenume: prenume,
                subject: subiect,
                from_mesaj: mesaj,
                from_email: email,
                email_to: 'daimondexpert2023@gmail.com'
            }, 'MQnzdaTwVarFtHtE2');
            setNume('');
            setPrenume('');
            setEmail('');
            setSubiect('');
            setMesaj('');
            setAcord(false);
            setEroare(false);
            setSuccess(true);
        } else {
            setEroare(true);
            setSuccess(false);
            console.log('eroare');
        }
    }

    return (
        <div className='contact-div padding'>
            <div className='contact-left'>
                <Fade className='map' duration={3000} triggerOnce={true}>
                    <div>
                        <iframe title='google-location' width="100%" height="400" frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0" src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=aleea%20salviei%20techirghiol%20nr%209+(Daimond%20Expert)&amp;t=&amp;z=15&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"><a href="https://www.gps.ie/">gps systems</a></iframe>
                    </div>
                </Fade>
                <h2>{t('location.title')}</h2>
                <div className='flex'>
                    <IoLocationSharp className='orange-filter' />
                    <div>
                        <h3>{t('location.address_title')}</h3>
                        <p>{t('location.address')}</p>
                    </div>
                </div>
                <div className='flex'>
                    <PiPhoneDuotone className='orange-filter' />
                    <div className='flex'>
                        <h3>{t('location.phone_title')}</h3>
                        <a href='tel:0762573066' className='contact-phone'>{t('location.phone_number')}</a>
                    </div>
                </div>
                <a href="https://www.facebook.com/profile.php?id=61555676706565" target='_blank' rel='noopener noreferrer'><FaFacebook className='facebook'/></a>
            </div>

            <form action="" className='contact-form'>
                <p>{t('form.contact_us')}</p>
                <h2>{t('form.send_message')}</h2>
                <div className='form-inputs'>
                    <div style={{ display: 'flex', columnGap: '24px' }} className='form-inputs-div'>
                        <div>
                            <p>{t('form.name')} *</p>
                            <input type="text" placeholder={t('form.name')} value={nume} onChange={(e) => { setNume(e.target.value) }} />
                        </div>
                        <div>
                            <p>{t('form.surname')} *</p>
                            <input type="text" placeholder={t('form.surname')} value={prenume} onChange={(e) => { setPrenume(e.target.value) }} />
                        </div>
                    </div>
                    <div style={{ display: 'flex', columnGap: '24px' }} className='form-inputs-div'>
                        <div>
                            <p>{t('form.email')} *</p>
                            <input type="email" placeholder={t('form.email')} value={email} onChange={(e) => { setEmail(e.target.value) }} />
                        </div>
                        <div>
                            <p>{t('form.subject')} *</p>
                            <input type="text" placeholder={t('form.subject')} value={subiect} onChange={(e) => { setSubiect(e.target.value) }} />
                        </div>
                    </div>
                    <div>
                        <p>{t('form.message')} *</p>
                        <textarea name="" id="" className='mesaj-text' placeholder={t('form.message')} value={mesaj} onChange={(e) => { setMesaj(e.target.value) }}></textarea>
                    </div>
                    <div className='acord-div'>
                        <div className='checkbox' onClick={() => { setAcord(!acord) }}>
                            {acord ? <FaCheck /> : ''}
                        </div>
                        <p>{t('form.agreement')}</p>
                    </div>
                </div>

                <button className='secondary-btn' onClick={trimiteMesaj}>{t('form.send_message')}</button>
                {eroare ? <p style={{ color: 'red' }}>{t('form.required_fields')}</p> : ''}
                {success ? <p style={{ color: 'green', fontWeight: '700' }}>{t('form.success_message')}</p> : ''}
            </form>
        </div>
    );
}

export default Contact;

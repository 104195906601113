import './Phone.css';
import { PiPhoneDuotone } from "react-icons/pi";

function Phone() {
  return (
    <div className='phone-div padding'>
      <PiPhoneDuotone style={{color:'var(--orange)', scale:'1.6'}}/>
      <a href='tel:0762573066'>0762 573 066</a>
    </div>
  );
}

export default Phone;
import './Despre.css';
import { useTranslation } from 'react-i18next';
import { Slide } from "react-awesome-reveal";
import { LiaTrophySolid } from "react-icons/lia";
import { MdConstruction } from "react-icons/md";
import { PiHandshake } from "react-icons/pi";
import workerImg from '../../assets/worker-img.png'
import workerImg2 from '../../assets/two-colleagues-factory_1303-14331.jpg'


function Despre(props) {
    const { t } = useTranslation();
    return (
      <div className='despre-div'>
        <div className='servicii-top-despre'>
          <h2>
            <Slide direction='up' triggerOnce='true'>
              {t('despre-text.top.title')}
            </Slide>
          </h2>
        </div>
        <div className='padding despre-title'>
          <p>{t('despre-text.mission.title')}</p>
          <h1>{t('despre-text.mission.headline')}</h1>
          <div className='despre-card-list'>
            <div className='despre-card'>
              <LiaTrophySolid className='icon blue-filter'/>
              <h2>{t('despre-text.mission.quality.title')}</h2>
              <p>{t('despre-text.mission.quality.description')}</p>
            </div>
            <div className='despre-card'>
              <PiHandshake className='icon blue-filter'/>
              <h2>{t('despre-text.mission.collaborations.title')}</h2>
              <p>{t('despre-text.mission.collaborations.description')}</p>
            </div>
            <div className='despre-card'>
              <MdConstruction className='icon blue-filter'/>
              <h2>{t('despre-text.mission.projects.title')}</h2>
              <p>{t('despre-text.mission.projects.description')}</p>
            </div>
          </div>
        </div>
        <div className='despre-text padding'>
          <div className='text'>
            <h1>{t('despre-text.about.title')}</h1>
            <p>{t('despre-text.about.description')}</p>
            <button className='secondary-btn' onClick={()=>{props.footerChangePage('servicii')}}>{t('servicii')}</button>
          </div>
          <img className='despre-img' src={workerImg} alt="" />
        </div>
        <div className='de-ce-noi'>
          <h2>{t('why_us')}</h2>
          <div className='padding de-ce-noi-text'>
              <p>{t('de-ce')}</p>
              <img className='despre-img' src={workerImg2} alt="" />
          </div>
          <button className='secondary-btn' onClick={()=>{props.footerChangePage('contact')}}>{t('contacteaza_ne')}</button>
        </div>
      </div>
    );
  }
  
  export default Despre;